(function ($) {
    $.fn.Uploader = function (options) {
        
        var container = $(this);
        var list = container.find('.uploader-list');
        var dropzone;

        var options = $.extend(true, {}, container.data('options'), options);
        container.removeAttr('data-options');
        
        handleButtons();
        initSortable();
        initDropzone();
        
        function initDropzone(){
            options.dropzone.init = function(){
                dropzone = this;
                
                addMockFiles();
                
                dropzone.on("totaluploadprogress", handleEventTotalUploadProcess)
                    .on("addedfile", handleEventAddedFile)
                    .on("sending", handleEventSending)
                    .on("queuecomplete", handleEventQueueComplete)
                    .on("error", handleEventError)
                    .on("success", handleEventSuccess);
            };
            
            container.dropzone(options.dropzone);
        }
        
        function addMockFiles(){
            if(list.data('files') != undefined){
                $(list.data('files')).each(function(i, file){
                    file.status = Dropzone.ADDED
                    
                    dropzone.emit("addedfile", file);
                    
                    if(file.url){
                        ext = getExtension(file.url);
                        if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif' || ext == 'webp'){
                            dropzone.emit("thumbnail", file, file.url);
                        }
                    }else if(file.blob){
                        dropzone.emit("thumbnail", file, file.blob);
                    }
                    
                    dropzone.files.push(file);
                    
                    $(file.previewElement).removeClass('busy');
                    $(file.previewElement).find('figure').append(getFileIcon(file.name));
                    
                    getPreviewForm($(file.previewElement), file);
                });
                
                list.removeAttr('data-files');
                list.sortable('refresh');
                list.trigger('sortupdate');
            }
        }
        
        function handleEventAddedFile(file){
            console.log(file);
            $(file.previewElement).find('figure').append(getFileIcon(file.name));
        }
        
        function handleEventTotalUploadProcess(progress){
            container.find('.uploader-total-process .progress-bar').css('width', progress + '%');
        }
        
        function handleEventSending(file){
            container.find('.uploader-total-process').fadeIn();
        }
        
        function handleEventQueueComplete(progress){
            setTimeout(function(){
                container.find('.uploader-total-process').fadeOut();
                setTimeout(function(){
                    container.find('.uploader-total-process .progress-bar').css('width', '0%');
                }, 1000);
            }, 400);
        }
        
        function handleEventError(file, data, xhr){
            setTimeout(function(){
                $(file.previewElement).removeClass('busy').addClass('error');

                $(file.previewElement).find('.progress-bar')
                    .removeClass('progress-bar-success')
                    .addClass('progress-bar-danger')
                    .css('width', '100%');
            }, 400);
        }
        
        function handleEventSuccess(file, data, xhr){
            $(file.previewElement).removeClass('busy').addClass('success');
            getPreviewForm($(file.previewElement), data);
            
            list.sortable('refresh');
            list.trigger('sortupdate');
        }
        
        function getPreviewForm(element, data){
            info = element.find('.uploader-item-info');
            
            if(data.temp != undefined){
                info.append('<input type="hidden" data-input="temp" value="'+ data.temp +'"><input type="hidden" data-input="name" value="'+ data.name +'">');
            }else{
                info.append('<input type="hidden" data-input="id" value="'+ data.id +'">');
            }
            
            info.append('<input type="hidden" data-input="sort">'+
                        '<div class="input-group">'+
                            '<input type="text" data-input="title" class="form-control" placeholder="Titel" value="'+ (data.title != undefined ? data.title : data.name) +'">'+
                            '<span class="input-group-btn">'+
                                '<a class="btn btn-danger"><i class="fa fa-trash"></i></a>'+
                            '</span>'+
                        '</div>'+
                        '<textarea class="form-control" data-input="description" placeholder="Omschrijving">'+ (data.description != undefined ? data.description : '') +'</textarea>');
            
            
            info.find('.btn-danger').confirmation({
                container: 'body',
                placement: 'left',
                title: 'Weet u het zeker?',
                btnOkClass: 'btn btn-xs btn-danger',
                btnOkLabel: 'Ja',
                btnCancelLabel: 'Nee',
                btnOkIcon: 'fa fa-check',
                btnCancelIcon: 'fa fa-times',
                singleton: true,
                popout: true,
                onConfirm: function () {
                    if(data.id != undefined){
                        $.ajax({
                            dataType: 'json',
                            method: 'DELETE',
                            headers: {
                                'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/cms/media/'+ data.id
                        }).done(function (response) {
                            if (!response.error) {
                                element.fadeOut();
                                notify('success', response.message);
                                
                                setTimeout(function(){
                                    element.remove();
                                }, 400);
                                
                            } else {
                                notify('danger', response.message);
                            }
                        });
                    }else{
                        element.fadeOut();
                        setTimeout(function(){
                            element.remove();
                        }, 400);
                    }
                }
            });
        }
        
        function handleButtons(){
            container.find('button').click(function (e) {
                e.preventDefault();
            });
        }
        
        function initSortable(){
            list.sortable({
                placeholder: 'uploader-item'
            }).on('sortupdate', function(){
                list.find('.uploader-item').each(function(i){
                    name = options.type +'['+ (i+1) +']';
                    $(this).find('[data-input=temp]').attr('name', name +'[temp]');
                    $(this).find('[data-input=id]').attr('name', name +'[id]');
                    $(this).find('[data-input=sort]').attr('name', name +'[sort]').val((i+1));
                    $(this).find('[data-input=name]').attr('name', name +'[name]');
                    $(this).find('[data-input=title]').attr('name', name +'[title]');
                    $(this).find('[data-input=description]').attr('name', name +'[description]');
                });
            });
        }
        
        function getFileIcon(filename){
            switch(getExtension(filename)) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    return $('<i class="fa fa-file-image-o"></i>');
                    break;                         
                case 'zip':
                case 'rar':
                    return $('<i class="fa fa-file-zip-o"></i>');
                    break;
                case 'pdf':
                    return $('<i class="fa fa-file-pdf-o"></i>');
                    break;
                case 'txt':
                    return $('<i class="fa fa-file-text-o"></i>');
                    break;
                case 'mp3':
                case 'wav':
                    return $('<i class="fa fa-file-audio-o"></i>');
                case 'mp4':
                case 'mov':
                    return $('<i class="fa fa-file-video-o"></i>');
                case 'xls':
                case 'xlsx':
                    return $('<i class="fa fa-file-excel-o"></i>');
                    break;
                case 'doc':
                case 'docx':
                    return $('<i class="fa fa-file-word-o"></i>');
                    break;
                case 'ppt':
                case 'pptx':
                    return $('<i class="fa fa-file-powerpoint-o"></i>');
                    break;
                default:
                    return $('<i class="fa fa-file-file-o"></i>');
            }
        }
        
        function getExtension(filename){
            return filename.substr( (filename.lastIndexOf('.') + 1) );
        }
    }
})(jQuery);
(function ($) {
    $.fn.Selector = function () {

        var $modal;
        var $container = $(this);
        var $name = $container.data('name');
        var $list = $container.find('.headers-list');
        var currentRequest = null;

        initModal();
        initButtons();
        initSortable();
        if ($container.data('toggle') === 'library') {
            initExistingLibraryItems();
        } else {
            initExistingHeaders();
        }

        function initModal() {
            $modal = $('<div class="modal fade headers-modal">' +
                '<div class="modal-dialog modal-lg">' +
                '<div class="modal-content">' +
                '<div class="modal-header">' +
                '<div class="row">' +
                '<div class="col-sm-6 col-md-4">' +
                '<h4 class="modal-title">' + ($name ? $name.charAt(0).toUpperCase() + $name.substr(1) : 'Headers') + ' selecteren</h4>' +
                '</div>' +
                '<div class="col-sm-6 col-md-4 text-right search"></div>' +
                '<div class="col-md-4 text-right filter actions">' +
                '<button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-remove"></i></button>' +
                '<button type="button" class="btn btn-default" data-button="refresh"><i class="fa fa-refresh"></i></button>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '<div class="modal-body headers-list"></div>' +
                '<div class="modal-footer">' +
                '<button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>' +
                '<button type="button" class="btn btn-success" data-button="add">Toevoegen</button>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>').appendTo($('body'));

            var groups = $container.attr('data-groups');

            if (typeof groups !== 'undefined' && groups !== '') {
                groups = groups.split(';');
                const $filter = $('<select>', {id: 'headerGroupFilter', class: ''});
                $filter.append($('<option value="" selected>Filter op groep</option>'));

                $.each(groups, function (i, group) {
                    $filter.append($('<option>', {value: group}).text(group));
                });

                $filter.prependTo($modal.find('.filter'));

                $filter.on('change', function () {
                    const group = $(this).val();
                    const search = $('#headerSearch').val();

                    getHeaders(group, search);
                });
            }

            var search = $container.attr('data-search');
            if (typeof search !== 'undefined' && search === 'true') {
                const $search = $('<input>', {id: 'headerSearch', class: 'form-control', placeholder: 'Zoeken'});

                $search.prependTo($modal.find('.search'));

                $search.on('change blur keyup', function () {
                    const search = $(this).val();
                    const group = $('#headerGroupFilter').val();

                    getHeaders(group, search);
                });
            }

            $modal.modal({
                show: false
            }).on('show.bs.modal', function (e) {
                getHeaders();
            })
        }

        function initButtons() {
            $container.find('[data-button=show]').click(function (event) {
                event.preventDefault();

                $modal.modal('show');
            });

            $modal.find('[data-button=refresh]').click(function () {
                getHeaders();
            });

            $modal.find('[data-button=add]').click(function () {
                add();
            });
        }

        function initSortable() {
            $list.sortable({
                placeholder: 'headers-item'
            }).on('sortupdate', function () {
                $list.find('.headers-item').each(function (i) {
                    name = $name + '[' + (i + 1) + ']';
                    $(this).find('[data-input=id]').attr('name', name + '[id]');
                    $(this).find('[data-input=name]').attr('name', name + '[name]');
                    $(this).find('[data-input=url]').attr('name', name + '[url]');
                    $(this).find('[data-input=order]').attr('name', name + '[order]').val((i + 1));
                });
            });
        }

        function initExistingHeaders() {
            items = $container.data('headers');
            if (items != '') {

                $(items).each(function (i, data) {
                    var element = buildElement($name, data).appendTo($list);

                    element.find('.btn-danger').click(function (event) {
                        event.preventDefault();
                        element.fadeOut();
                        setTimeout(function () {
                            element.remove();
                        }, 400);
                    });
                });

                $list.sortable('refresh');
                $list.trigger('sortupdate');
            }
        }

        function initExistingLibraryItems() {
            items = $container.data('headers');
            if (items != '') {

                $(items).each(function (i, data) {
                    var element = buildElement($name, data).appendTo($list);

                    element.find('.btn-danger').click(function (event) {
                        event.preventDefault();

                        if (data.primary) {
                            element.confirmation({
                                container: 'body',
                                placement: 'bottom',
                                title: 'Weet u het zeker?',
                                content: 'Deze foto komt bij dit item vandaan en wordt met deze actie permanent verwijderd.',
                                btnOkClass: 'btn btn-xs btn-danger',
                                btnOkLabel: 'Ja',
                                btnCancelLabel: 'Nee',
                                btnOkIcon: 'fa fa-check',
                                btnCancelIcon: 'fa fa-times',
                                singleton: true,
                                popout: true,
                                onConfirm: function () {
                                    if(data.id != undefined){
                                        $.ajax({
                                            dataType: 'json',
                                            method: 'DELETE',
                                            headers: {
                                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                                            },
                                            url: '/cms/media/'+ data.id
                                        }).done(function (response) {
                                            if (!response.error) {
                                                element.fadeOut();
                                                notify('success', response.message);

                                                setTimeout(function(){
                                                    element.remove();
                                                }, 400);

                                            } else {
                                                notify('danger', response.message);
                                            }
                                        });
                                    }else{
                                        element.fadeOut();
                                        setTimeout(function(){
                                            element.remove();
                                        }, 400);
                                    }
                                }
                            });
                        } else {
                            element.fadeOut();
                            setTimeout(function () {
                                element.remove();
                            }, 400);
                        }
                    });
                });

                $list.sortable('refresh');
                $list.trigger('sortupdate');
            }
        }

        function add() {
            $modal.find('.headers-list .headers-item.selected').each(function (i, element) {
                element = $(this).clone().appendTo($container.find('.headers-list'));

                element.find('.btn-danger').click(function (event) {
                    event.preventDefault();
                    element.fadeOut();
                    setTimeout(function () {
                        element.remove();
                    }, 400);
                });
            });

            $modal.modal('hide');

            $list.sortable('refresh');
            $list.trigger('sortupdate');
        }

        function getHeaders(group, search) {
            var list = $modal.find('.headers-list');
            var loader = $('<div class="alert alert-info"><i class="fa fa-refresh fa-spin"></i> Bezig met laden, moment geduld</div>');
            var msg = $('<div class="alert alert-warning">Geen ' + $name + ' gevonden om toe te voegen</div>');
            var url = $container.data('url');

            const params = [];
            if (typeof group !== 'undefined' && group !== '') {
                params.push('group=' + group);
            }

            if (typeof search !== 'undefined' && search !== '') {
                params.push('search=' + search);
            }

            if (params.length > 0) {
                url += '?' + params.join('&');
            }

            currentRequest = $.ajax({
                dataType: 'json',
                method: 'GET',
                headers: {
                    'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: url,
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                    }
                    $modal.find('.alert').remove();
                    list.empty().before(loader);
                },
                success: function (response) {
                    $modal.find('.alert').remove();

                    $(response).each(function (i, data) {
                        if ($.inArray(data.id, getSelectedIds()) === -1) {
                            element = buildElement($name, data).appendTo(list);

                            element.click(function () {
                                $(this).toggleClass('selected');
                            }).dblclick(function () {
                                $(this).addClass('selected');
                                $modal.find('[data-button=add]').trigger('click');
                            });
                        }
                    });

                    if (list.find('.headers-item').length == 0) {
                        list.before(msg);
                    }
                }
            });
        }

        function getSelectedIds() {
            values = [];
            $list.find('[data-input=id]').each(function () {
                values.push(parseInt($(this).val()));
            });

            return values;
        }

        function buildElement(type, data) {
            if (type === 'galleries') {
                element = $('<div class="headers-item">' +
                    '<input type="hidden" data-input="id" value="' + data.id + '">' +
                    '<input type="hidden" data-input="url" value="' + data.first_image + '">' +
                    '<input type="hidden" data-input="order">' +
                    '<figure>' +
                    '<img src="' + data.first_image + '">' +
                    '</figure>' +
                    '<input type="text" class="form-control" value="' + data.title + '" readonly="readonly">' +
                    '<div class="input-group">' +
                    '<input type="text" class="form-control" data-input="name" value="' + data.title + '" readonly="readonly">' +
                    '<span class="input-group-btn">' +
                    '<button class="btn btn-danger"><i class="fa fa-times"></i></button>' +
                    '</span>' +
                    '</div>' +
                    '</div>');
            } else {
                element = $('<div class="headers-item">' +
                    '<input type="hidden" data-input="id" value="' + data.id + '">' +
                    '<input type="hidden" data-input="url" value="' + data.url + '">' +
                    '<input type="hidden" data-input="order">' +
                    '<figure>' +
                    '<img src="' + data.url + '">' +
                    '</figure>' +
                    '<div class="text-center">' + data.name + '</div>' +
                    '<div class="input-group">' +
                    '<input type="text" class="form-control" data-input="name" value="' + data.name + '" readonly="readonly">' +
                    '<span class="input-group-btn">' +
                    '<button class="btn btn-danger"><i class="fa fa-times"></i></button>' +
                    '</span>' +
                    '</div>' +
                    '</div>');
            }

            return element;
        }
    };
})(jQuery);

$(window).ready(function () {
    if ($('[data-toggle=headers]').length > 0) {
        $('[data-toggle=headers]').Selector();
    }
    if ($('[data-toggle=banners]').length > 0) {
        $('[data-toggle=banners]').Selector();
    }
    if ($('[data-toggle=galleries]').length > 0) {
        $('[data-toggle=galleries]').Selector();
    }
    if ($('[data-toggle=library]').length > 0) {
        $('[data-toggle=library]').each(function () {
            $(this).Selector();
        });
    }
});
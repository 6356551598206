(function ($) {
    $.fn.HeaderUploader = function (dropzone_options) {

        var container = $(this).attr('id', uniqueId());
        var dropzone;
        var guillotine;

        var mobile = container.data('formatter') == 'format_mobile' ? true : false;

        handleButtons();
        initDropzone();

        function initDropzone() {
            defaults = {
                clickable: '#' + container.attr('id') + ' .uploader-browse',
                previewsContainer: '#' + container.attr('id') + ' .uploader-list',
                previewTemplate: previewTemplate(),
                headers: {
                    'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                maxFiles: 1,
                acceptedFiles: '.jpg, .jpeg, .png',

                thumbnailWidth: null,
                thumbnailHeight: null,

                init: function () {
                    dropzone = this;

                    addImage();

                    dropzone.on("totaluploadprogress", handleEventTotalUploadProcess)
                        .on("addedfile", handleEventAddedFile)
                        .on("error", handleEventError)
                        .on("success", handleEventSuccess);
                }
            };
            options = $.extend(true, {}, defaults, dropzone_options);

            container.dropzone(options);
        }

        function addImage() {
            var image = container.find('.uploader-list').data('image');
            if (image != '') {
                image.status = Dropzone.ADDED;
                dropzone.emit("addedfile", image);

                if (image.url) {
                    var img = new Image();
                    img.onload = function () {
                        changeLayout(image.coordinates);
                    };
                    img.src = image.url;

                    dropzone.emit("thumbnail", image, image.url);
                    dropzone.files.push(image);
                } else if (image.blob) {
                    dropzone.emit("thumbnail", image, image.blob);
                    dropzone.files.push(image);

                    $(image.previewElement).prepend('<input type="hidden" data-input="name" name="image[name]" value="' + image.name + '">');
                    $(image.previewElement).prepend('<input type="hidden" data-input="temp" name="image[temp]" value="' + image.temp + '">');

                    changeLayout(image.coordinates);
                }

            }
        }

        function previewTemplate() {
            return '<div class="uploader-guillotine">' +
                '<input type="hidden" data-input="coordinates" name="'+ (mobile ? 'imagemobile[coordinates]' : 'image[coordinates]') + '">' +
                '<img class="guillotine-image" data-dz-thumbnail>' +
                '</div>';
        }

        function handleEventAddedFile(file) {
            container.find('.uploader-total-process').fadeIn();
        }

        function handleEventTotalUploadProcess(progress) {
            container.find('.uploader-total-process .progress-bar').css('width', progress + '%');
        }

        function handleEventError(file, data, xhr) {
            file.previewElement.remove()

            notify('danger', data);
            setTimeout(function () {
                container.find('.uploader-total-process .progress-bar')
                    .removeClass('progress-bar-success')
                    .addClass('progress-bar-danger')
                    .css('width', '100%');
            }, 400);
        }

        function handleEventSuccess(file, data, xhr) {
            $(file.previewElement).prepend('<input type="hidden" data-input="name" name="' + (mobile ? 'imagemobile[name]' : 'image[name]') + '" value="' + data.name + '">');
            $(file.previewElement).prepend('<input type="hidden" data-input="temp" name="' + (mobile ? 'imagemobile[temp]' : 'image[temp]') + '" value="' + data.temp + '">');

            changeLayout(null);
        }

        function changeLayout(coordinates) {
            setTimeout(function () {
                setTimeout(function () {
                    container.find('.guillotine-upload').hide();
                    container.find('.guillotine-buttons').show();
                    container.find('.uploader-total-process').hide().find('.progress-bar').css('width', '0%');
                }, 1000);
                container.find('.uploader-guillotine').addClass('show');
                initGuillotine(coordinates);
            }, 200);
        }

        function initGuillotine(coordinates) {
            options = {
                width: getFormat().w,
                height: getFormat().h,
                onChange: function (data, action) {
                    container.find('[data-input=coordinates]').val(JSON.stringify(data));
                }
            }

            if (coordinates != null) {
                options.init = coordinates;
                container.find('[data-input=coordinates]').val(JSON.stringify(coordinates));
            }

            element = container.find('.guillotine-image');  // Must be already loaded or cached!
            element.guillotine(options);
            guillotine = element.guillotine('instance');
            if (coordinates == null) {
                guillotine.fit();

            }
        }

        function handleButtons() {
            container.find('button').click(function (e) {
                e.preventDefault();
            });

            container.find('[data-button=delete]').click(function () {
                dropzone.removeAllFiles();
                container.find('.guillotine-buttons').hide();
                container.find('.guillotine-upload').show();

                guillotine = undefined;
            });

            container.find('[data-button=rotateLeft]').click(function () {
                guillotine.rotateLeft();
            });

            container.find('[data-button=zoomIn]').click(function () {
                guillotine.zoomIn();
            });

            container.find('[data-button=fit]').click(function () {
                guillotine.fit();
            });

            container.find('[data-button=zoomOut]').click(function () {
                guillotine.zoomOut();
            });

            container.find('[data-button=rotateRight]').click(function () {
                guillotine.rotateRight();
            });

            $('#format' + (mobile ? '_mobile' : '')).change(function () {
                if (guillotine != undefined) {
                    guillotine.remove();
                    initGuillotine(null);
                }
            });
        }

        function getFormat() {
            format = $('#format' + (mobile ? '_mobile' : '')).val().split('x');

            return {w: format[0], h: format[1]};
        }

        function uniqueId() {
            return 'header' + Math.round(new Date().getTime() + (Math.random() * 100));
        }
    }
})(jQuery);